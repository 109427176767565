<template>
  <div>
    <section id="company-profile">
      <div class="container">
        <h1 class="text-center">We provide a wide range of professional services</h1>
        <p class="text-center">
          Erik Wallace and Sindre Bergan founded Wallace Solutions in 2009. They were primarily translating English to Norwegian and vice versa, along with Danish and Swedish source texts. With clients repeatedly requesting translations into Danish, Finnish and Swedish we started recruiting linguists to create a one-stop solution for clients in need of professional translations between English and all Scandinavian languages.
          <br/>
          After having established a number of successful collaborations for these language pairs, we opened our Bangkok office in 2015. This was when the potential for Thai translation became evident, and we have since established a diverse and dynamic team with 6 project managers, several in-house Scandinavian linguists and 14 in-house Thai linguists.
        </p>
      </div>
    </section>
    <section>
      <div class="container">
        <p class="text-center">Our services including the following:
          <br>
          <b>Translation, Localization, Editing, Revision, Proofreading, Transcreation, Transcription, Subtitling, Linguistic Quality Assurance (LQA), Linguistic Sign-Off (LSO), Spot-check, Layout Proofreading, Quality Assessment, Test Evaluation</b>
        </p>
      </div>
    </section>
    <section id="process">
      <div class="section-heading">
        <h1>Our process</h1>
      </div>
      <div class="container">
        <div class="feature">
          <div>
            <img :src="require('@/assets/images/message.svg')" alt>
            <h3>Translation</h3>
            <p>Source content is translated by the most suitable linguist for the task; a native speaker of the target language with relevant experience in the topic or field. This step includes spelling and grammar checks, QA checks and self-review, also keeping in mind language specific punctuation rules, consistency in terminology and TM/Glossary adherence.</p>
          </div>
          <div>
            <img :src="require('@/assets/images/write.svg')" alt>
            <h3>Editing</h3>
            <p>Editing of a translation is carried out by a separate linguist, to ensure that the translation is optimal in terms of fluency, meaning and syntax. An additional QA check is carried out to maintain consistency in terminology.</p>
          </div>
          <div>
            <img :src="require('@/assets/images/checkmark.svg')" alt>
            <h3>Final Check</h3>
            <p>The third step involves an in-house check of the final document. Typically, the final check is carried out by the same linguist who performed the translation, after going through the edits with the editor.</p>
          </div>
        </div>
      </div>
    </section>
    <!-- <section id="pricing">
      <div class="container">
        <div class="section-heading">
          <h1>Pricing</h1>
          <p>
            We offer a
            <span>15% discount</span> on the first order for new clients.
          </p>
        </div>
        <div class="card-wrapper">
          <div class="card">
            <h1 class="card-title">English/Nordic*</h1>
            <ul>
              <li>
                Translation
                <span>€0.1 psw</span>
              </li>
              <li>
                Proofreading
                <span>€0.04 psw</span>
              </li>
              <li>
                TEP
                <span>€0.12 psw</span>
              </li>
              <li>
                Editing/Hourly
                <span>€40.00</span>
              </li>
            </ul>
            <span class="card-info">*psw (per source word)</span>
          </div>
          <div class="card card-center">
            <div>
              <h1 class="card-title">English to Thai</h1>
              <ul>
                <li>
                  Translation
                  <span>€0.06 psw</span>
                </li>
                <li>
                  Proofreading
                  <span>€0.025 psw</span>
                </li>
                <li>
                  TEP
                  <span>€0.08 psw</span>
                </li>
                <li>
                  Editing/Hourly
                  <span>€25.00</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="card card-center">
            <div>
              <h1 class="card-title">Thai to English</h1>
              <ul>
                <li>
                  Translation
                  <span>€0.065 psw</span>
                </li>
                <li>
                  Proofreading
                  <span>€0.03 psw</span>
                </li>
                <li>
                  TEP
                  <span>€0.085 psw</span>
                </li>
                <li>
                  Editing/Hourly
                  <span>€30.00</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section>
      <div class="section-heading">
        <h1>Let's get started</h1>
        <p>We are ready to help you at a moment’s notice. Get in touch with our PM team for more information.</p>
      </div>
      <div class="container text-center">
        <a class="button" href="mailto:projects@wallacesolutions.net">Get in touch</a>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

section:first-of-type {
  .container {
    h1 {
      margin-bottom: 30px;
    }
  }
}

#process {
  background: linear-gradient(to top, #fff, #f8f8f8);
}

#process p span {
  color: $primary-color;
}

#company-profile{
  br{
    display: block;
    margin: 20px 0;
    content: " ";
  }
}
</style>
<script>
export default {
  metaInfo: {
    title: "Wallace Solutions",
    titleTemplate: "%s | Services"
  }
};
</script>
